/* eslint-disable quotes */
export function concatString(string: string, maxLength: number): string {
  return string?.length > maxLength
    ? `${string.substring(0, maxLength)}...`
    : string;
}
export function convertSnakeToCamelCase(snakeCaseString: string): string {
  return snakeCaseString.replace(/_([a-z])/g, (match, letter) =>
    letter.toUpperCase()
  );
}

export function convertSnakeToCapitalCase(snakeCaseString: string): string {
  return snakeCaseString
    ?.replace(/_/g, " ") // Replace underscores with spaces
    ?.replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
}

/**
 * Encodes special characters to HTML entities.
 * Converts &, <, >, ", and ' to their HTML entity equivalents.
 */
export function encodeHTML(value: string): string {
  return value
    ?.replace(/&/g, "&amp;")
    ?.replace(/>/g, "&gt;")
    ?.replace(/</g, "&lt;")
    ?.replace(/"/g, "&quot;")
    ?.replace(/'/g, "&#039;"); // Handles single quotes as well
}

/**
 * Decodes HTML entities back to their original characters.
 * Converts &amp; to &, &lt; to <, &gt; to >, &quot; to ", and &#039; to '.
 */
export function decodeHTML(value: string): string {
  return value
    ?.replace(/&amp;/g, "&")
    ?.replace(/&gt;/g, ">")
    ?.replace(/&lt;/g, "<")
    ?.replace(/&quot;/g, '"')
    ?.replace(/&#039;/g, "'"); // Handles single quotes as well
}

/**
 * Convert each word in the Camel case.
 */
export function capitalize(str: string | undefined): string {
  if (!str) {
    return "";
  }

  if (str.length === 2) {
    return str.toUpperCase();
  }

  return str
    .toLowerCase()
    .split(" ")
    .map((word) =>
      word.length === 2
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
}
