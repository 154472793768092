"use client";

import { useEffect } from "react";

import { CookieKeys } from "@/enums/CookieKeys.enum";
import { useCookies } from "@/hooks/useCookies";

export default function CookieCleanup(): JSX.Element {
  const { removeCookie } = useCookies();

  useEffect(() => {
    const handleTabClose = (): void => {
      [
        CookieKeys.EmailCollect,
        CookieKeys.EmailCollectClose,
        CookieKeys.Redirect,
      ].forEach((key) => {
        removeCookie(key);
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    };

    window.addEventListener("pagehide", handleTabClose);

    return () => {
      window.removeEventListener("pagehide", handleTabClose);
    };
  }, []);

  return <></>;
}
