import { useEffect, useState } from "react";
import { Button, Heading, Text } from "ui-components";

import { PLACEHOLDER_IMAGE } from "@/src/constants/images";

const isAndroid = (): boolean => /android/i.test(navigator.userAgent);
const isIOS = (): boolean => /iPad|iPhone|iPod/.test(navigator.userAgent);

export function MobileAppPrompt(): JSX.Element {
  const [isMobile, setMobile] = useState<boolean | null>(null);
  const goToApplication = (): void => {
    const iosScheme = "mygwork://"; // Replace with actual deep link scheme
    const androidScheme =
      "intent://home#Intent;scheme=mygwork;package=com.mygwork.mygwork2;action=android.intent.action.VIEW;end";
    const iosStoreUrl = "https://apps.apple.com/us/app/mygwork/id947603126";
    const androidStoreUrl =
      "https://play.google.com/store/apps/details?id=com.mygwork.mygwork2";

    if (isIOS()) {
      setMobile(true);
      const now = new Date().getTime();
      window.location.href = iosScheme;
      setTimeout(() => {
        if (new Date().getTime() - now < 2000) {
          window.location.href = iosStoreUrl; // Redirect to App Store
        }
      }, 1500);
    } else if (isAndroid()) {
      setMobile(true);
      window.location.href = androidScheme;
      setTimeout(() => {
        window.location.href = androidStoreUrl; // Redirect to Play Store
      }, 1500);
    }
  };

  useEffect(() => {
    if (isAndroid() || isIOS()) {
      setMobile(true);
    }
  }, []);

  if (isMobile === null) {
    return <></>;
  }

  return (
    <div className="mb-24">
      <div className="fixed top-0 left-0 right-0 p-4 bg-white shadow-md flex items-center justify-between z-50">
        <img
          src={PLACEHOLDER_IMAGE}
          alt="myGwork Logo"
          className="h-10 w-10 mr-3"
        />
        <div className="flex flex-col flex-1">
          <Heading variant="h2">myGwork</Heading>
          <Text className="text-sm">
            Connect, network, and find LGBTQ+ inclusive jobs.
          </Text>
        </div>
        <Button onClick={goToApplication} className="!w-28">
          Go to app
        </Button>
      </div>
    </div>
  );
}
